.Layout {
  display: flex;
  justify-content: center;
  align-items: center;

  @include minM {
    height: 100dvh;
    background-color: var(--color-primary);
    background-image: var(--background-image);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }

  &-container {
    width: 100%;
    background-color: var(--color-background);

    @include minM {
      max-width: 45rem;
      border-radius: 1.6rem;
      border: .1rem solid $grey1;
    }
  }

  &-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.6rem 0;
    border-bottom: .1rem solid $grey1;
    color: var(--color-text-filled);
    background-color: var(--color-backgroundBloc);

    @include minM {
      border-radius:  1.6rem 1.6rem 0 0;
      background-color: unset;
    }
  }

  &-title {
    margin: 0;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 700;
    font-family: 'CustomFontSecondary', 'Heebo', sans-serif;

    &--sub {
      margin-bottom: 1.5rem;
    }
  }

  &-content {
    padding: 0 2.4rem 2.4rem;

    @include minM {
      padding: 0 4.8rem 4.8rem;
    }

    &--flCenter {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &-icon {
    --icon-size: 1.2rem;
    position: absolute;
    width: var(--icon-size);
    height: var(--icon-size);
    cursor: pointer;

    &--left {
      left: 1.6rem;
    }
    &--right {
      right: 1.6rem;
    }
  }

  & a.Layout-icon {
    color: inherit;
  }
}
