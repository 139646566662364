@import "../../utils/css/variables";

.Profile {
  &-title {
    margin: 0;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 700;
    color: var(--color-text-filled);
    font-family: 'CustomFontSecondary', 'Heebo', sans-serif;

    &--sub {
      margin-bottom: 1.5rem;
    }
  }

  &-logo {
    margin: 1.6rem auto;
    width: 100%;
    height: 3.2rem;
    object-fit: contain;
  }

  &-text {
    margin-bottom: 1.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.2rem;
    color: var(--color-text);

    &--center {
      text-align: center;
    }

    & strong {
      color: var(--color-text-filled);
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-top: 1rem;
    width: 100%;

    @include minM {
      flex-direction: row;
    }

    & .Button + .Button {
      margin: 0;
    }
  }

  &-content {
    padding: 0 2.4rem 2.4rem;

    @include minM {
      padding: 0 4.8rem 4.8rem;
    }

    &--flCenter {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &--text {
      padding: 2.4rem 2.4rem;

      @include minM {
        padding: 4.8rem 4.8rem;
      }
    }
  }

  &-iconClose {
    --icon-size: 1.2rem;
    position: absolute;
    right: 1.6rem;
    width: var(--icon-size);
    height: var(--icon-size);
  }

  &-iconSablier {
    --icon-size: 2.6rem;
    height: auto;
    color: var(--color-text);
  }

  &-iconBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.6rem;
    width: 7.2rem;
    height: 7.2rem;
    border-radius: 100%;
    border: .1rem solid var(--color-text);
    background-color: var(--color-backgroundBloc);
  }

  &-image {
    margin: 1.6rem 0;
  }

  &-partner {
    --space-between-partners: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem var(--space-between-partners);
    flex-wrap: wrap;
    margin-bottom: 1.6rem;
    opacity: 50%;

    &--dark {
      opacity: 1;
    }

    &Container {
      position: relative;
      display: flex;
      align-items: center;
      max-width: 6rem;

      & + & {
        &:before {
          content: '';
          position: absolute;
          left: calc((var(--space-between-partners) * -1) / 2);
          display: block;
          width: 0.1rem;
          height: 3rem;
          background-color: $grey1;
        }
      }
    }

    &Logo {
      width: 6rem;
      height: auto;
    }
  }
}

.Layout-container {
  @include minM {
    margin-top: 7.5rem;
  }
}
