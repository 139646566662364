.Button {
  display: inline-block;
  padding: 1.3rem 2rem;
  border-radius: .2rem;
  border: 0;
  width: 100%;
  color: var(--button-text-color);
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  line-height: normal;
  background-color: var(--color-primary);
  transition: background-color 0.2s ease;
  cursor: pointer;

  & + & {
    margin-top: 1.5rem;
  }

  &[disabled] {
    pointer-events: none;
    background-color: $grey3;
  }

  &--outlined {
    border: .1rem solid var(--color-text-filled);
    color: var(--color-text-filled);
    background-color: unset;
  }

  &--red {
    color: #fff;
    background-color: $red;

    &:hover {
      background-color: color-mix(in srgb, $red 85%, black);
    }
  }
}
