@import "../../utils/css/variables";

.Toast {
  display: flex;
  align-items: center;
  gap: 3rem;
  color: var(--color-primary);
  font-weight: bold;

  &-icon {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;

    & .Icon {
      --icon-size: 2rem;
    }

    &:after {
      content: '';
      position: absolute;
      right: -1.8rem;
      width: .1rem;
      height: 100%;
      background-color: $grey1;
    }
  }

  &-close {
    position: absolute;
    right: .5rem;
    top: 1rem;
    color: $grey2;
    cursor: pointer;

    & .Icon {
      --icon-size: 1rem;
    }
  }
}
