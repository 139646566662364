@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700;900&display=swap');

$primary: #4054CC;
$grey: #c8cbcf;
$grey1: #D8DAE8;
$grey2: #959DAF;
$grey3: #696863;
$bgLight: #F4F6F8;
$red: #E81E29;

$fontDark: #272727;

$minXS: 375px;
$minS: 480px;
$minM: 667px;
$minL: 1200px;

@mixin minXS {
  @media (min-width: $minXS) { @content; }
}

@mixin minS {
  @media (min-width: $minS) { @content; }
}

@mixin minM {
  @media (min-width: $minM) { @content; }
}

@mixin minL {
  @media (min-width: $minL) { @content; }
}

@mixin customScrollbar {
  scrollbar-color: $grey2 #fff;
  scrollbar-width: thin;

  @include onlyForFirefox {
    padding-right: 1.4rem;
  }

  &::-webkit-scrollbar {
    width: 1.6rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 10rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10rem;
    border: .5rem solid transparent;
    background-clip: content-box;
    background-color: $grey2;
  }
}

@mixin onlyForFirefox {
  @-moz-document url-prefix() {
    @content;
  }
}
