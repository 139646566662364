.Dropdown {
  &-lock {
    overflow: hidden;
  }

  &-overlay {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    background: rgba(0,0,0,.5);
    z-index: 99;
  }

  &-menu {
    display: none;
    padding: 1rem;
    border-radius: .8rem;
    width: calc(100% - 1.6rem);
    height: auto;
    max-width: 35rem;
    max-height: 45rem;
    background-color: var(--color-backgroundBloc);
    overflow: hidden;
    opacity: 0;
    transition: all 300ms;

    &.show {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      animation: animateDrop 300ms forwards;
      z-index: 100;
    }
  }

  &-options {
    @include customScrollbar;

    max-height: 31rem;
    overflow-y: scroll;
  }

  &-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: .8rem 0 .2rem;
    padding: .8rem 1.6rem;
    border-radius: .8rem .8rem .2rem .2rem;
    color: var(--color-text);
    background-color: var(--color-backgroundBloc2);

    &Head {
      display: flex;
      align-items: center;
      gap: .8rem;
      font-weight: 500;
    }

    &Length {
      font-size: 1.2rem;
    }
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    gap: .8rem;
    padding: .8rem 1.6rem;
    border: .1rem solid transparent;
    border-radius: .2rem;
    width: 100%;
    color: var(--color-text-filled);
    background-color: var(--color-backgroundBloc2);
    text-decoration: none;
    cursor: pointer;

    & + & {
      margin-top: .2rem;
      border-radius: .2rem;
    }

    &::before {
      content: "";
      position: absolute;
      flex: 0 0 2rem;
      width: 2rem;
      height: 2rem;
      border: .1rem solid var(--color-secondary);
      border-radius: 100%;
      background-color: var(--color-backgroundBloc2);
    }

    &:hover {
      background-color: color-mix(in srgb, var(--color-secondary) 8%, transparent);
    }

    &--active {
      position: relative;
      border-color: var(--color-secondary);
      background-color: color-mix(in srgb, var(--color-secondary) 8%, transparent);

      &::before {
        background-color: var(--color-secondary);
      }

      &::after {
        content: "";
        position: absolute;
        top: 2rem;
        left: 2.2rem;
        width: .8rem;
        height: .4rem;
        border: .2rem solid var(--color-background);
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
      }
    }
  }

  &-logo {
    width: 3.2rem;
    height: 3.2rem;

    &--head {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &-noLogo {
    border-radius: 100%;
    height: 3.2rem;
  }

  &-radio {
    width: 2rem;
    height: 2rem;
    accent-color: var(--color-primary);
    visibility: hidden;
  }

  &-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .8rem;
    padding: .8rem 1.6rem;
    border: .1rem solid var(--color-text);
    width: 100%;
    background-color: transparent;
    border-radius: .2rem;
    text-align: left;
    color: var(--color-text);
    line-height: 3rem;
    cursor: pointer;

    &:hover {
      @include minM {
        border-color: var(--color-primary);
      }

      & + .FormElement-label {
        color: var(--color-primary);
      }

      & .Icon {
        color: var(--color-primary);
      }
    }

    & .Icon {
      --icon-size: 1rem;
      width: var(--icon-size);
      height: var(--icon-size);
    }

    &--error {
      border-color: var(--color-error);
    }
  }

  &-label {
    margin-right: auto;
    color: var(--color-text-filled);
  }

  &-search {
    margin-bottom: .8rem;
    padding: .8rem 1.6rem;
    border: .1rem solid var(--color-text);
    border-radius: .4rem;
    width: 100%;
    line-height: 3rem;

    &::placeholder {
      color: $grey2;
    }
  }

  &-button {
    margin-top: .8rem;
  }

  &-error {
    display: block;
    padding: 1.6rem;
    color: var(--color-error);
  }
}

@keyframes animateDrop
{
  from
  {
    opacity: 0;
  }
  to
  {
    opacity: 1;
  }
}
