@import "utils/css/reset";
@import "utils/css/variables";
@import "utils/css/button";
@import "utils/layout/Layout";

body {
  font-size: 1.6rem;
  font-family: 'CustomFont', 'Heebo', sans-serif;
  background-color: var(--color-background);

  @include minM {
    background-color: unset;
  }
}

.Icon {
  --icon-size: 1.2rem;
  display: flex;
  width: var(--icon-size);
  height: var(--icon-size);
}
