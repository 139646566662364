@import "./Dropdown/Dropdown";
.FormElement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
  width: 100%;

  &-description {
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: $grey2;
  }

  &-container {
    width: 100%;
  }

  &-group {
    position: relative;
    margin-top: 1rem;
    width: 100%;

    @include minM {
      max-width: 35rem;
    }

    &--margin {
      margin-top: 3rem;
    }

    &--search {
      margin-top: 0;
      line-height: 3rem;
    }

    &--checkboxToggle {
      padding: .8rem;

      & .FormElement-label {
        margin-bottom: 0;
      }
    }

    &--checked {
      transition: all .2s ease;
      border-radius: .4rem;
      background-color: color-mix(in srgb, var(--color-secondary) 8%, transparent);

      & .FormElement-label {
        color: var(--color-primary);
      }
    }
  }

  &-label {
    position: absolute;
    top: .8rem;
    left: 0;
    display: block;
    margin-bottom: .5rem;
    color: var(--color-text);
    font-weight: 500;
    white-space: nowrap;
    cursor: text;

    &--top {
      font-size: 1rem;
      transition: transform 0.3s ease;
      transform: translateY(-1.5rem);
    }

    &--search {
      top: 1rem;
      left: 1.6rem;
    }

    &--description {
      display: block;
      padding-right: 1.6rem;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $grey2;
    }

    &--error {
      color: var(--color-error);
    }
  }

  &-input {
    position: relative;
    display: block;
    padding: 1rem 0 0.2rem;
    border: 0;
    border-bottom: 1px solid var(--color-text);
    width: 100%;
    color: var(--color-text-filled);
    background-color: transparent;
    outline: 0;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 0s 600000s, color 0s 600000s;
    }

    &::placeholder {
      font-size: 0;
    }

    &::-webkit-calendar-picker-indicator {
      margin-right: .6rem;
      width: 2.4rem;
      height: 2.4rem;
    }

    &:hover {
      border-bottom: 1px solid var(--color-primary);

      & ~ .FormElement-label {
        color: var(--color-primary);
      }
    }

    &:focus {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid var(--color-primary);
      outline: 0;
      transition: background-color 0.5s ease;
      cursor: text;
      box-shadow: none;

      &:not([type=date]):not(:read-only) ~ label {
        font-size: 1rem;
        color: var(--color-primary);
        transition: 0.3s ease;
        transform: translateY(-1.6rem);

        & .FormElement-required {
          top: -.2rem;
        }
      }
    }

    &:not(:focus):not(:read-only):not([type=date]) + label {
      transition: 0.3s ease;
      transform: translateY(0);
    }

    &:valid:not(:placeholder-shown):not([type=checkbox]):not([type=radio]),
    &:read-only:not(:placeholder-shown):not([type=checkbox]):not([type=radio]) {
      & ~ label {
        font-size: 1rem;
        transition: 0.3s ease;
        transform: translateY(-1.6rem);

        & .FormElement-required {
          top: -.2rem;
        }
      }

      &:read-only {
        opacity: 0.5;
      }
    }

    &:invalid:not(:placeholder-shown):not([type=checkbox]):not([type=radio]) {
      /*border-color: $red;*/

      & ~ label {
        font-size: 1rem;
        transition: 0.3s ease;
        transform: translateY(-1.6rem);
        /*color: $red;*/

        & .FormElement-required {
          top: -.2rem;
        }
      }
    }

    &--search {
      height: 100%;
      margin-bottom: .8rem;
      padding: 2rem 1.6rem .2rem;
      border: 0.1rem solid var(--color-text);
      border-radius: 0.4rem;

      &:focus {
        border: .1rem solid var(--color-primary);

        & + label {
          transform: translateY(-1.2rem);

          & .FormElement-required {
            top: -.2rem;
          }
        }
      }

      &:hover {
        border: 0.1rem solid var(--color-primary);

        & ~ .FormElement-label {
          color: var(--color-primary);
        }
      }

      &:valid:not(:placeholder-shown) + label {
        transform: translateY(-1.2rem);

        & .FormElement-required {
          top: -.2rem;
        }
      }
    }

    &--date[type="date"] {
      &::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgb(216, 218, 232)" d="M441.3 42.6h-43.2V12.2c0-7-5.6-12.6-12.6-12.6s-12.6 5.6-12.6 12.6v30.4h-61.4V12.2c0-7-5.6-12.6-12.6-12.6s-12.6 5.6-12.6 12.6v30.4h-61.4V12.2c0-7-5.6-12.6-12.6-12.6s-12.6 5.6-12.6 12.6v30.4h-61.4V12.2c0-7-5.6-12.6-12.6-12.6s-12.6 5.6-12.6 12.6v30.4H3.1v469.1h505.2V42.6h-67zm42.1 444.1H28.2v-419h85.3v26.7c0 7 5.6 12.6 12.6 12.6s12.6-5.6 12.6-12.6V67.7H200v26.7c0 7 5.6 12.6 12.6 12.6s12.6-5.6 12.6-12.6V67.7h61.4v26.7c0 7 5.6 12.6 12.6 12.6s12.6-5.6 12.6-12.6V67.7H373v26.7c0 7 5.6 12.6 12.6 12.6s12.6-5.6 12.6-12.6V67.7h85.3c-.1 0-.1 419-.1 419z"/><path fill="rgb(216, 218, 232)" d="M110.6 276H142v31.5h-31.5V276zm.3 85.4h31.5v31.5h-31.5v-31.5zm86.1 0h31.5v31.5H197v-31.5zm86.2 0h31.5v31.5h-31.5v-31.5zM109.8 190.5h31.5V222h-31.5v-31.5zm86.3 85.5h31.5v31.5h-31.5V276zm86.2 0h31.5v31.5h-31.5V276zm86.2 0H400v31.5h-31.5V276zm-173.2-85.5h31.5V222h-31.5v-31.5zm86.2 0H313V222h-31.5v-31.5zm86.2 0h31.5V222h-31.5v-31.5z"/></svg>');
        background-size: 2.4rem;
        background-repeat: no-repeat;
      }
    }

    &--checkbox {
      display: none;

      & + .FormElement-label {
        position: initial;
        display: flex;
        align-items: center;
        line-height: 2.2rem;
        color: var(--color-text-filled);
        font-size: 1.6rem;
        white-space: initial;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          color: var(--color-text-filled);
        }

        &:before {
          content: '';
          -webkit-appearance: none;
          position: relative;
          display: inline-block;
          margin-right: 1.6rem;
          padding: 1rem;
          border: .1rem solid var(--color-text-filled);
          border-radius: .4rem;
          background-color: transparent;
          cursor: pointer;
        }

        a {
          color: var(--color-secondary);
        }
      }

      &:checked + .FormElement-label {
        &:before {
          border: .1rem solid var(--color-secondary);
          background-color: var(--color-secondary);
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 2rem;
          height: 2rem;
          border: 0.1rem solid transparent;
          background-image: url('data:image/svg+xml;utf8,<svg data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 372.41"><path  style="fill:%23161616;" d="m502.33 12.83-2.04-2.05c-14.18-14.29-37.26-14.38-51.55-.2l-.2.2-271.38 271.76L63.11 168.16c-14.79-14.29-38.36-13.89-52.65.9-14.29 14.79-13.89 38.36.9 52.65L151.25 361.6c14.14 14.29 37.18 14.42 51.47.28l.28-.28L500.28 64.33c14.79-13.66 15.7-36.72 2.04-51.5Z"/></svg>');
          background-size: 1rem;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      &:disabled {
        & + .FormElement-label {
          opacity: 50%;
          cursor: not-allowed;

          &:before {
            cursor: not-allowed;
          }
        }
      }
    }

    &--checkboxToggle {
      & + .FormElement-label {
        flex-direction: row-reverse;

        &:before {
          flex: 0 0 5.6rem;
          margin-left: auto;
          margin-right: 0;
          padding: 0;
          width: 5.6rem;
          height: 2.4rem;
          border-radius: 2rem;
          border-color: $grey1;
          background-color: #fff;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          right: 3.4rem;
          width: 3.2rem;
          height: 3.2rem;
          background-image: url('data:image/svg+xml;utf8,<svg data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23696773" d="M316.11 255.92 499.4 73.06c16.71-16.72 16.71-43.81 0-60.53-16.72-16.71-43.81-16.71-60.53 0L256.01 195.82 73.15 12.53c-16.71-16.71-43.81-16.71-60.52 0-16.71 16.72-16.71 43.81 0 60.53l183.29 182.86L12.63 438.78c-16.71 16.55-16.85 43.51-.31 60.22l.31.31c16.58 16.71 43.57 16.82 60.28.25l.25-.25 182.86-183.29 182.86 183.29c16.56 16.77 43.57 16.94 60.34.38 4.1-4.05 7.34-8.89 9.52-14.23 6.66-16.02 2.96-34.47-9.38-46.67L316.12 255.93Z"/></svg>');
          background-size: 1.3rem;
          background-repeat: no-repeat;
          background-position: center;
          background-color: $grey1;
          border-radius: 50%;
          transition: .3s;
        }

      }

      &:checked + .FormElement-label {
        &:before {
          border-color: var(--color-secondary);
          background-color: var(--color-background);
        }

        &:after {
          width: 3.2rem;
          height: 3.2rem;
          background-image: url('data:image/svg+xml;utf8,<svg data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 372.41"><path style="fill:%23161616;" d="m502.33 12.83-2.04-2.05c-14.18-14.29-37.26-14.38-51.55-.2l-.2.2-271.38 271.76L63.11 168.16c-14.79-14.29-38.36-13.89-52.65.9-14.29 14.79-13.89 38.36.9 52.65L151.25 361.6c14.14 14.29 37.18 14.42 51.47.28l.28-.28L500.28 64.33c14.79-13.66 15.7-36.72 2.04-51.5Z"/></svg>');
          background-color: var(--color-secondary);
          background-size: 1.4rem;
          transform: translateX(2.6rem);
        }
      }

      &:disabled {
        & + .FormElement-label {
          opacity: 50%;
          cursor: not-allowed;

          &:before {
            cursor: not-allowed;
          }
        }
      }
    }
  }

  &-dropdown {
    display: inline-block;
    position: relative;
    padding: 1rem 0 0 0;
    width: 100%;
    font-size: 1.6rem;
  }

  &-error {
    position: absolute;
    display: block;
    margin-top: .5rem;
    font-size: 1.2rem;
    color: var(--color-error);
  }

  &-required {
    top: -.4rem;
    margin-left: .2rem;
    color: var(--color-error);
    font-size: inherit;
  }

  &-reset {
    position: absolute;
    top: 50%;
    right: .8rem;
    transform: translateY(-50%);
    padding: 0;
    color: $grey1;
    cursor: pointer;
    z-index: 1;

    &:hover {
      color: var(--color-primary);
    }

    & .Icon {
      --icon-size: 2.4rem;
    }
  }

  &-invalid {
    margin-bottom: 1rem;

    & .FormElement {
      &-input {
        border-color: var(--color-error);
      }

      &-label {
        color: var(--color-error);
      }
    }
  }
}
