.Loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: color-mix(in srgb, var(--color-primary) 15%, transparent);
  backdrop-filter: blur(2px);
  z-index: 99;

  & .Icon {
    --icon-size: 3.2rem;

    & .spinner {
      transform-origin: center;
      animation: rotate .75s step-end infinite
    }
  }
}

@keyframes rotate{
  8.3%{transform:rotate(30deg)}
  16.6%{transform:rotate(60deg)}
  25%{transform:rotate(90deg)}
  33.3%{transform:rotate(120deg)}
  41.6%{transform:rotate(150deg)}
  50%{transform:rotate(180deg)}
  58.3%{transform:rotate(210deg)}
  66.6%{transform:rotate(240deg)}
  75%{transform:rotate(270deg)}
  83.3%{transform:rotate(300deg)}
  91.6%{transform:rotate(330deg)}
  100%{transform:rotate(360deg)}
}
