@import "../../utils/css/variables";
@import "../../components/FormElement/FormElement";

.CustomError {
  width: 100%;
  
  &-container {
    justify-content: space-between;
    display: flex;

    a {
      color:#0a84ae;
    }
  }

  &-details {
    margin: 20px auto 0;
    background-color: #f8efbf;
    color : #bb9455;
    padding: 20px;
  }
}
